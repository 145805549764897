import { Swiper, SwiperSlide } from "swiper/react"
import { MainBanner2Arr } from "../elements/JsonData"
import { Parallax, Pagination } from "swiper/modules"
import { div, useNavigate } from "react-router-dom"

const MainBanner2 = () => {
  const pagination = {
    clickable: true,
    el: ".main-swiper3-pagination",
    renderBullet: function(index, className) {
      return '<span class="' + className + '">' + (index + 1) + "</span>"
    }
    
  }
  const navigate = useNavigate()
  return (
    <div className="main-bnr-three overflow-hidden top-space">
      
      <Swiper
        className="main-slider-3"
        direction="horizontal"
        modules={[Parallax, Pagination]}
        parallax={true}
        speed={1500}
        spaceBetween={0}
        slidesPerView={1}
        pagination={pagination}
        loop={false}
      >
        {MainBanner2Arr.map(
          ({ title, name, price, bgimg, img1, img2, img3, img4 }, ind) => (
            <SwiperSlide className="" key={ind}>
              <div
                className="banner-inner overflow-hidden"
                data-swiper-parallax="-10"
                data-swiper-parallax-duration="0.5"
                style={{
                  backgroundImage: `url(${bgimg})`,
                  backgroundSize: "cover",
                  borderRadius: "10px",
                }}
              >
                <div className="container">
                  <div
                    className="row align-items-center"
                    data-swiper-parallax="-100"
                  >
                    <div className="col-xl-7 col-lg-7 col-md-8">
                      <div className="banner-content" style={{width:'90%'}}>
                        <span className="sub-title text-black">
                          High Quality
                        </span>
                        <h1 className="title" style={{fontSize: '30px', overflow:'hidden'}}>
                          Indulge in authentic flavors: The Ultimate Destination for Best Cantonese Cuisine in Town
                        </h1>
                        <p className="bnr-text text-black">
                          餐馆接收各类宴会，婚姻预定，让您留下难忘的美好回忆
                        </p>

                        <div className="banner-btn d-flex align-items-center">
                          <div
                            onClick={() => navigate("/online-order")}
                            className="btn btn-primary btn-md shadow-primary m-r30 btn-hover-1"
                          >
                            <span>Order Online</span>
                          </div>
                          <div
                            onClick={() => navigate("/menu")}
                            className="btn btn-outline-primary btn-md shadow-primary btn-hover-1"
                          >
                            <span>View Menu</span>
                          </div>
                        </div>
                        
                      </div>
                    </div>
                    <div className="col-xl-5 col-lg-5 col-md-4">
                      <div className="banner-media">
                        <img
                          src={img1}
                          alt="/"
                          data-swiper-parallax-scale="0.8"
                          style={{ borderRadius: "10px"} }

                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* <img className="leaf" src={img3} alt="/" style={{borderRadius: '10px'}} /> */}
              </div>
            </SwiperSlide>
          )
        )}
      </Swiper>
    </div>
  )
}

export default MainBanner2
