import React from 'react';

const CategoryModal = ({ show, onClose, children }) => {
  return (
    <div className={`custom-modal-overlay ${show ? 'show' : ''}`} onClick={onClose}>
      <div className="custom-modal text-center" onClick={(e) => e.stopPropagation()}>
        {children}
      </div>
    </div>
  );
};

export default CategoryModal;
