import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import restaurantReducer from "./restaurant";
import cartReducer from "./cart";
import logger from "redux-logger"
import sessionReducer from "./session";
import stripeReducer from "./stripe";
import orderReducer from "./order";
import couponReducer from "./coupons";
import freeitemsReducer from "./freeitems";

const rootReducer = combineReducers({
  // add reducer functions here
  restaurants: restaurantReducer,
  cart: cartReducer,
  stripe: stripeReducer,
  coupons: couponReducer,
  freeItems: freeitemsReducer,
  orders: orderReducer,

});

let enhancer;

if (process.env.NODE_ENV === "production") {
  enhancer = applyMiddleware(thunk);
} else {
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  enhancer = composeEnhancers(applyMiddleware(thunk, logger));
}

const configureStore = (preloadedState) => {
  return createStore(rootReducer, preloadedState, enhancer);
};

export default configureStore;
