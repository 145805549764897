import { Modal, Button, Form, InputGroup } from 'react-bootstrap';
import { useState, useEffect, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import {Context} from "../context/AppContext";

const EditCartModal = ({ item, modal, setModal }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [special_instructions, setSpecialInstructions] = useState('');
  const [quantity, setQuantity] = useState(1);
  const [alertMessages, setAlertMessages] = useState({});
  const [disabledAddToCart, setDisabledAddToCart] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const { changedCart, setChangedCart } = useContext(Context);

  useEffect(() => {
    if (modal && item) {
      // Prefill selected options if the item already has some selected
      if (item?.options) {
        const optionsToAdd = item.options.flatMap((attribute) =>
          ({
            id: attribute.id,
            priceModifier: attribute.priceModifier,
            attributeId: attribute.attributeId,
          })
        );
        setSelectedOptions(optionsToAdd);
      }

      // Prefill quantity if the item already has a quantity set
      if (item.quantity) {
        setQuantity(item.quantity);
      }

      if (item.special_instructions) {
        setSpecialInstructions(item.special_instructions);
      } else {
        setSpecialInstructions('');
      }

    }
  }, [modal, item]);

  useEffect(() => {
    const checkRequiredOptions = () => {
      let allOptionsSelected = true;
      let alerts = {};

      item?.Restaurant_Item_Attributes.forEach((attribute) => {
        const requiredOptions = attribute?.Item_Attribute?.requ || 0;
        const selectedOptionsCount = selectedOptions.filter(opt => opt.attributeId === attribute.id).length;

        if (requiredOptions > selectedOptionsCount || requiredOptions !== selectedOptionsCount && requiredOptions !== 0) {
          allOptionsSelected = false;
          alerts[attribute.id] = `*Must Select ${requiredOptions} options and you selected ${selectedOptionsCount}`;
        } else {
          alerts[attribute.id] = '';
        }
      });

      setAlertMessages(alerts);
      setDisabledAddToCart(!allOptionsSelected);
    };

    checkRequiredOptions();
  }, [selectedOptions, item]);

  useEffect(() => {
    if (!modal) {
      // Reset state when modal is closed
      setSelectedOptions([]);
      setSpecialInstructions('');
      setQuantity(1);
      setAlertMessages({});
      setDisabledAddToCart(false);
    }
  }, [modal]);

  const handleIncrease = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  const handleDecrease = () => {
    if (quantity > 1) {
      setQuantity((prevQuantity) => prevQuantity - 1);
    }
  };

  const handleChange = (e) => {
    const newQuantity = parseInt(e.target.value);
    if (!isNaN(newQuantity)) {
      setQuantity(newQuantity);
    }
  };

  const handleOptionChange = (optionId, priceModifier, attributeId) => {
    const existingOption = selectedOptions.find((opt) => opt.id === optionId);

    if (existingOption) {
      // If the option is already selected, remove it
      setSelectedOptions((prevOptions) =>
        prevOptions.filter((opt) => opt.id !== optionId)
      );
    } else {
      // If the option is not selected, add it
      setSelectedOptions((prevOptions) => [
        ...prevOptions,
        { id: optionId, priceModifier, attributeId },
      ]);
    }
  };

  // Calculate the total price based on selected options
  const calculateTotalPrice = () => {
    let totalPrice = +item?.price || 0;
    selectedOptions.forEach((option) => {
      totalPrice += +option.priceModifier || 0;
    });

    totalPrice *= +quantity;
    return totalPrice?.toFixed(2);
  };

  // Handle add item to cart
// Handle add item to cart
const handleUpdateItem = () => {
  setIsLoading(true);
  const cart = JSON.parse(localStorage.getItem('cart')) || [];

  const updatedItem = {
    Item: item,
    quantity,
    special_instructions,
    options: selectedOptions,
    price: calculateTotalPrice(),
  };


  // Update the item at the specific index
  cart[item.index] = updatedItem;

  localStorage.setItem('cart', JSON.stringify(cart));
  setChangedCart(!changedCart);
  setIsLoading(false);
  setModal(false);
};

  
  // Handle remove item from cart
  const handleRemoveItem = () => {
    setIsDeleting(true);
    const localCart = JSON.parse(localStorage.getItem('cart')) || [];
  
    // Remove the item at the specific index
    localCart.splice(item.index, 1);
  
    localStorage.setItem('cart', JSON.stringify(localCart));
    setIsDeleting(false);
    setModal(false);
    setChangedCart(!changedCart);
  };
  return (
    <>   
     <Modal show={modal} onHide={() => setModal(false)} centered scrollable>
      <Modal.Header closeButton>
        <Modal.Title>{item?.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column align-items-center">
          {item?.image_url && (
            <img
              src={item?.image_url}
              alt={item?.name}
              className="img-fluid mb-3"
              style={{ width: '100%', maxHeight: '220px', objectFit: 'cover' }}
            />
          )}
          <p>{item?.description?.length > 1 ? item?.description : item?.name}</p>
          {/* Attributes Section */}
          {item?.Restaurant_Item_Attributes && item?.Restaurant_Item_Attributes?.length > 0 && (
            <div className="w-100">
              <h6>Options:</h6>
              <Form>
                {item?.Restaurant_Item_Attributes?.map((attribute) => (
                  <Form.Group key={attribute.id} className="mb-3">
                    <Form.Label className="mb-2">{attribute?.Item_Attribute?.name} <br />
                      <small className="text-danger">{alertMessages[attribute.id]}</small>
                    </Form.Label>

                    <div className="d-flex flex-wrap">
                      {attribute?.Item_Attribute?.Item_Attribute_Options?.map((option) => (
                        <div className='checkbox-wrapper-47 m-0' key={option.id}>
                          <input
                            type="checkbox"
                            id={option.name}
                            name={option.name}
                            value={option.id}
                            onChange={() => handleOptionChange(option.id, option.price_modifier, attribute.id)}
                            checked={selectedOptions.some((opt) => opt.id === option.id)}
                          />
                          <label htmlFor={option.name}>{option.name} {option?.price_modifier > 0 ? `+ $${option.price_modifier}` : null}</label>
                        </div>
                      ))}
                    </div>
                  </Form.Group>
                ))}
              </Form>
            </div>
          )}
          {/* Special Instructions */}
          <Form.Group className="w-100 mb-3">
            <Form.Label>Special Instructions:</Form.Label>
            <Form.Control value={special_instructions} onChange={(e) => setSpecialInstructions(e.target.value)} type="text" placeholder="Add special instructions..." />
          </Form.Group>
          {/* Quantity choose */}
        </div>
      </Modal.Body>
      <div className="row" style={{ backgroundColor: '#F0FFF0' }}>
      <div className="col-6" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '5px', textAlign: 'center', backgroundColor: '#F0FFF0' }}>
        <InputGroup style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <InputGroup.Text onClick={handleDecrease} style={{ cursor: 'pointer', backgroundColor: 'white' }}>
            <FontAwesomeIcon icon={faMinus} />
          </InputGroup.Text>
          <span style={{ fontSize: '18px', margin: '0 10px' }}>{quantity}</span>
          <InputGroup.Text onClick={handleIncrease} style={{ cursor: 'pointer', backgroundColor: 'white' }}>
            <FontAwesomeIcon icon={faPlus} />
          </InputGroup.Text>
        </InputGroup>
      </div>
      <div className='col-6' >
      <div className="col-6 w-100" style={{ backgroundColor: '#dc3545', color: 'white', padding: '10px', textAlign: 'center', cursor: 'pointer' }} onClick={handleRemoveItem}>
        {isDeleting ? <span className="spinner-border"></span> : `Remove`}
      </div>
      <div className={`col-6 w-100 ${disabledAddToCart ? ' disable-button ' : 'not-disable'}` } onClick={() =>
        disabledAddToCart ? null : handleUpdateItem()
      }
      
      >        {isLoading ? <span className="spinner-border"></span> : `Save $${calculateTotalPrice()}`}
      </div>
      </div>
    </div>
    </Modal>
    </>
  );


}

export default EditCartModal;
