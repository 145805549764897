import { IMAGES } from "../constent/theme"
import { Link } from "react-router-dom"
import emailjs from "@emailjs/browser"
import toast, { Toaster } from "react-hot-toast"
import { useRef, useState } from "react"

const Footer2 = () => {
  const heartRef = useRef(null)
  const [input, setInput] = useState("")
  const form = useRef(null)
  const sendEmail = e => {
    e.preventDefault()
    setInput("")
    if (form.current) {
      emailjs
        .sendForm(
          "emailId",
          "template_0byuv32",
          form.current,
          "qUDIPykc776NYHv4m"
        )
        .then(
          () => {
            toast.success("Successfully send!")
          },
          error => {
            toast.error(error.text)
          }
        )
    }
  }
  return (
    <>
      <Toaster position="bottom-right" reverseOrder={true} />
      <footer className="site-footer style-2" id="footer">
        <div className="footer-bg-wrapper" id="app-banner">
          <div className="footer-top">
            <div className="container">
            
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 wow fadeInUp">
                  <div className="widget widget_getintuch">
                    <h5 className="footer-title">Contact</h5>
                    <ul>
                      <li>
                        <i className="flaticon-placeholder"></i>
                        <a href="https://www.google.com/maps?q=3580+Peachtree+Industrial+Blvd,+Duluth,+GA+30096" target="">
                        3580 Peachtree Industrial Blvd, Duluth, GA 30096
                        </a>
                      </li>
                      <li>
                        <i className="flaticon-telephone"></i>
                        <a href="tel:+14702826718">
                        +1(470) 282-6718
                  
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-2 col-md-6 col-sm-6">
                  <div className="widget widget_services">
                    <h5 className="footer-title">Our Links</h5>
                    <ul>
                      <li>
                        <Link to="/">
                          <span>Home</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/about-us">
                          <span>About Us</span>
                        </Link>
                      </li>
                      
                      <li>
                        <Link to="/online-order">
                          <span>Online Order</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/menu">
                          <span>Menu</span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
                  <div className="widget widget_services">
                    <h5 className="footer-title">OUR SERVICES</h5>
                    <ul>
                  
                      <li>
                        <Link to="/">
                          <span>Top Chefs</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/contact-us">
                          <span>Services</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/">
                          <span>Pickup In Store</span>
                        </Link>
                      </li>
                     
                    </ul>
                  </div>
                </div>
                {/* <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6	"> */}
                  {/* <div className="widget widget_services"> */}
                    {/* <h5 className="footer-title">Help Center</h5>
                    <ul>
                      <li>
                        <Link to="/faq">
                          <span>FAQ</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/shop-style-1">
                          <span>Shop</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/shop-style-2">
                          <span>Category Filter</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/testimonial">
                          <span>Testimonials</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/contact-us">
                          <span>Contact Us</span>
                        </Link>
                      </li> */}
                    {/* </ul> */}
                  {/* </div> */}
                </div>
              {/* </div> */}
            </div>
          </div>
        </div>
        <div className="container">
          <div className="footer-bottom">
            <div className="row">
                <span className="copyright-text">
                  Crafted With{" "}
                  <span
                    className="heart"
                    ref={heartRef}
                    onClick={() => {
                      heartRef.current?.classList.toggle("heart-blast")
                    }}
                  ></span>{" "}
                  by{" "}
                  <Link to="/">
                    HuTao Online
                  </Link>
                </span>
              </div>
              
            </div>
        </div>
      </footer>
    </>
  )
}

export default Footer2
