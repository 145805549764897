import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import './index.css';
import App from './App';
import configureStore from '../src/store/';
import { AppContextProvider } from './context/AppContext';
import { createRoot } from 'react-dom/client';

const store = configureStore();

if (process.env.NODE_ENV !== "production") {
  window.store = store;
}

function Root() {
  return (
    <AppContextProvider>
      <ReduxProvider store={store}>
        <App />
      </ReduxProvider>
    </AppContextProvider>
  );
}

// Import createRoot from react-dom/client

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
root.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>
);
