import React, { useContext, useEffect, useState } from "react";
import { Modal, Button, Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import {Context} from "../context/AppContext";
import EditCartModal from "./EditCartModal";
import AddToCartModal from "./AddCartModal";
import { useNavigate } from "react-router-dom";
import { getItemAttributeNameById } from "../functions";

const ViewCartModal = ({ show, handleClose, currentCart, restaurant }) => {
  const navigate = useNavigate();
  const { language, changedCart, setChangedCart } = useContext(Context);
  const [editCartModal, setEditCartModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [suggestedItems, setSuggestedItems] = useState([]);
  const [displayedItems, setDisplayedItems] = useState(new Set());
  const [addCartModal, setAddCartModal] = useState(false);

    const shuffleArray = (array) => {
        let shuffledArray = [...array];
        for (let i = shuffledArray.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
        }
        return shuffledArray;
      };

      const getSuggestedItems = (reset = false) => {
        const category = restaurant?.Categories;
        const allItems = category?.flatMap(cat => cat.Items) || [];
        let availableItems = allItems.filter(item => !currentCart.some(cartItem => cartItem.Item.id === item.id));
    
        if (!reset) {
          availableItems = availableItems.filter(item => !displayedItems.has(item.id));
        }
    
        if (availableItems.length === 0) {
          if (reset) {
            return;
          }
          setDisplayedItems(new Set());
          getSuggestedItems(true);
          return;
        }
    
        const shuffledItems = shuffleArray(availableItems);
        const newSuggestedItems = shuffledItems.slice(0, 3);
        setSuggestedItems(newSuggestedItems);
        setDisplayedItems(prev => new Set([...prev, ...newSuggestedItems.map(item => item.id)]));
      };

  useEffect(() => {
    if (show) {
      getSuggestedItems();
    }
  }, [show]);

  const updateSuggestedItems = () => {
    getSuggestedItems();
  };
    

  

  const handleEditCartModal = (item) => {
    setSelectedItem(item);
    setEditCartModal(true);
  };

  const handleRemoveItem = (item) => {
    const localCart = JSON.parse(localStorage.getItem('cart')) || [];
  
    // Remove the item at the specific index
    localCart.splice(item.index, 1);
  
    localStorage.setItem('cart', JSON.stringify(localCart));
    setChangedCart(!changedCart);
  };

  const handleAddCartModal = (item) => {
    setSelectedItem(item);
    setAddCartModal(true);
};

  
 



  const subtotal = currentCart.reduce((acc, item) => acc + (item.Item.price * item.quantity), 0);
  const tax = (subtotal * 0.06).toFixed(2);
  const total = (subtotal + parseFloat(tax)).toFixed(2);

  return (
    <>
    <div 
   >
      <Modal
        show={show}
        onHide={handleClose}
        centered
        animation
        fullscreen='xl-down'
        dialogClassName="view-cart-modal"
        className={`${addCartModal || editCartModal ? 'modal-dimmed' : ''}`}
        size="lg"
      >
        <Modal.Header closeButton                 
        className={`${addCartModal || editCartModal ? 'modal-dimmed' : ''}`}
        >
            <Modal.Title id="view-cart-modal-title">Your Cart</Modal.Title>
        </Modal.Header>
        <Modal.Body  
                className={`${addCartModal || editCartModal ? 'modal-dimmed' : ''}`}
 
                   style={{boxShadow:'rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset'}}
        >
          <Container>
           
            <div style={{ height: '50vh', overflowY:'auto', scrollbarColor:'black', scrollbarWidth:'thin'}} 
            >

              {currentCart.map((item, index) => (
                <div key={index} className="cart-item-view-cart d-flex align-items-start mb-3">
                  <img
                    src={item.Item.image_url || 'https://theme-assets.getbento.com/sensei/3914814.sensei/assets/images/catering-item-placeholder-704x520.png'}
                    alt={item.Item.name}
                    className="cart-item-image me-3"
                  />
                  <div className="cart-item-details flex-grow-1">
                    <p className="item-name mb-1">{language === 'en' ? item?.Item?.name : item?.Item?.chinese_name}</p>
                    {item?.options?.map((attribute, idx) => (
                      <p className="item-attribute small m-0" key={idx}>
                        {getItemAttributeNameById(attribute.id, item.Item)} {attribute?.priceModifier > 0 ? `+ $${attribute.priceModifier}` : null}
                      </p>
                    ))}
                    {item?.special_instructions && (
                      <p className="item-instructions small m-0">
                        <strong>**</strong> {item?.special_instructions}
                      </p>
                    )}
                    <div style={{ gap: '15px', display:'flex', alignItems:'center'}}>
                      <p className="viewcart-action-text"
                        onClick={() => handleEditCartModal({
                          id: item?.Item.id,
                          name: item?.Item?.name,
                          price: item?.Item.price,
                          quantity: item?.quantity,
                          special_instructions: item?.special_instructions,
                          image_url: item?.Item?.image_url,
                          Restaurant_Item_Attributes: item?.Item?.Restaurant_Item_Attributes,
                          options: item?.options,
                          index: index,
                        })}
                      >Edit</p>
                      <p className="viewcart-action-text"
                        onClick={() => handleRemoveItem({
                          id: item?.Item.id,
                          name: item?.Item?.name,
                          price: item?.Item.price,
                          quantity: item?.quantity,
                          special_instructions: item?.special_instructions,
                          image_url: item?.Item?.image_url,
                          Restaurant_Item_Attributes: item?.Item?.Restaurant_Item_Attributes,
                          options: item?.options,
                            index: index,
                        })}
                      >Remove</p>
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-center">
                    <span>{item.quantity}</span>
                  </div>
                  <p className="item-price ms-3">${item.Item.price}</p>
                </div>
              ))}
            </div>

            <hr />

            <div>
              <div className="d-flex justify-content-between align-items-center">
                <h5>Need anything else?</h5>
                <p onClick={() => updateSuggestedItems()} style={{ cursor: 'pointer', textDecoration:'underline'}}>More</p>
              </div>
              <div className="d-flex mb-2">
                {suggestedItems.map((item, index) => (
                    <div key={index} className="suggested-item text-center mx-2" style={{ position: 'relative', minWidth: '30%' }}>
  <div style={{ position: 'relative' }} onClick={() => handleAddCartModal(item)}>
    <img
      src={item.image_url || 'https://theme-assets.getbento.com/sensei/3914814.sensei/assets/images/catering-item-placeholder-704x520.png'}
      alt={item.name}
      className="suggested-item-image"
      style={{ width: '100%' }}
    />

    <FontAwesomeIcon
      icon={faPlusCircle}
      size="lg"
      className="text-primary"
      style={{fontSize:'25px', position: 'absolute', bottom: '10px', right: '10px', cursor: 'pointer', backgroundColor: 'white', borderRadius: '50%' }}
    />
  </div>
  <p className="suggested-item-name small">
    {item.name.length > 25 ? item.name.slice(0, 25) + '...' : item.name}
  </p>
  <p className="suggested-item-price">${item.price}</p>
</div>




                ))}
              </div>
            </div>

            <hr />

            <div className="d-flex justify-content-between">
              <p>Subtotal</p>
              <p>${subtotal.toFixed(2)}</p>
            </div>

            <div className="d-flex justify-content-between">
              <p>Tax</p>
              <p>${tax}</p>
            </div>

            <button style={{borderRadius:'15px'}} className="w-100 text-white bg-black p-2" onClick={() => navigate('/checkout')}>
              Checkout (${total})
            </button>

         
          </Container>
        </Modal.Body>
      </Modal>

    </div>
      <EditCartModal modal={editCartModal} setModal={() => setEditCartModal(false)} item={selectedItem}  />
       <AddToCartModal modal={addCartModal} setModal={() => setAddCartModal(false)} item={selectedItem}/>
    </>
  );
};



export default ViewCartModal;
