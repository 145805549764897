/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import logo from "../assets/images/client/chefducklogo.png";
import logo2 from "../assets/images/logo2.png";


import background from "../assets/images/bk.png"
import gallery1 from "../assets/images/client/image1.PNG"
import gallery2 from "../assets/images/client/image2.PNG"
import gallery3 from "../assets/images/client/image3.PNG"
import gallery4 from "../assets/images/client/image3.PNG"
import gallery5 from "../assets/images/client/image5.PNG"
import gallery6 from "../assets/images/client/image6.PNG"
import gallery7 from "../assets/images/client/image7.PNG"
import gallery8 from "../assets/images/client/image8.PNG"
import gallery9 from "../assets/images/client/image9.PNG"
import gallery10 from "../assets/images/client/image10.PNG"
import gallery11 from "../assets/images/client/image11.PNG"
import gallery12 from "../assets/images/client/image12.PNG"
import gallery13 from "../assets/images/client/image13.PNG"
import gallery14 from "../assets/images/client/image14.PNG"
import gallery15 from "../assets/images/client/image15.PNG"
import duck12 from "../assets/images/client/duck12.png"



export const IMAGES = {

  logo: logo,
  logo2: logo2,
  duck12: duck12,
    background: background,
    gallery1: gallery1,
    gallery2: gallery2,
    gallery3: gallery3,
    gallery4: gallery4,
    gallery5: gallery5,
    gallery6: gallery6,
    gallery7: gallery7,
    gallery8: gallery8,
    gallery9: gallery9,
    gallery10: gallery10,
    gallery11: gallery11,
    gallery12: gallery12,
    gallery13: gallery13,
    gallery14: gallery14,

    gallery15: gallery15,
};
