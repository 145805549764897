import { Link, useNavigate } from "react-router-dom";
import { IMAGES } from "../constent/theme";
import { useContext, useEffect, useRef, useState } from "react";
import { Context } from "../context/AppContext";
import { Modal, Button } from "react-bootstrap";
import Header from "../components/Header";

const MenuStyle4 = ({ restaurant }) => {
  const [active, setActive] = useState(0);
  const cardRef = useRef([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const { language } = useContext(Context);
  const navigate = useNavigate();


  // Function to calculate offset of category name relative to viewport
  const getCategoryOffset = (categoryId) => {
    const categoryElement = document.getElementById(`category-${categoryId}`);
    if (categoryElement) {
      const rect = categoryElement.getBoundingClientRect();
      return rect.top + window.scrollY - 200;
    }
    return 0;
  };
  useEffect(() => {
    const handleScroll = () => {
      const categoryElements = restaurant?.Categories.map((_, id) =>
        document.getElementById(`category-${id + 1}`)
    );
    
    const scrollPosition = window.scrollY + window.innerHeight / 2;
    let activeCategory = 0;
    
    categoryElements.forEach((element, index) => {
      if (element) {
        const rect = element.getBoundingClientRect();
        const offsetTop = rect.top + window.scrollY;
        
        if (scrollPosition >= offsetTop) {
          activeCategory = index + 1;
        }
      }
      });
      
      setActive(activeCategory);
    };
    
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [restaurant]);
  
  const handleCardClick = (item) => {
    setSelectedItem(item);
    setModalVisible(true);
  };

  return (
    <>
      <Header />
      <div className="page-content bg-white" style={{ marginTop: '100px' }}>
        <div className="category-menu-wrapper">
          <div className="category-menu">
            <ul className="list-unstyled d-flex align-items-center mb-0">
              <li
                className={`mr-3 p-2 rounded-pill ${active === 0 ? "active" : ""}`}
                onClick={() => {
                  setActive(0);
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
              >
                <Link to="#" style={{ fontSize: "1.1rem" }}>
                  All
                </Link>
              </li>
              {restaurant?.Categories &&
                restaurant.Categories.map(({ name }, id) => (
                  <li
                    className={`mr-3 p-2 rounded-pill category-item ${active === id + 1 ? "active" : ""}`}
                    key={id}
                    onClick={() => {
                      setActive(id + 1);
                      const categoryOffset = getCategoryOffset(id + 1);
                      window.scrollTo({
                        top: categoryOffset,
                        behavior: "smooth",
                      });
                    }}
                  >
                    <Link to={`#category-${id + 1}`} style={{ fontSize: "1.1rem" }}>
                      {name}
                    </Link>
                  </li>
                ))}
            </ul>
          </div>
        </div>
        <div className="container-fluid">
          {restaurant?.Categories &&
            restaurant.Categories.map(({ name, Items }, id) => (
              <div key={id} id={`category-${id + 1}`} className="">
                <h2>{name}</h2>
                <div className="row" style={{ overflowX: "auto" }}>
                  {Items.map(
                    (
                      { id, name, description, price, image_url, chinese_name, Restaurant_Item_Attributes },
                      ind
                    ) => (
                      <div
                        className="col-lg-3 col-md-4 col-6 m-b10 px-1"
                        onClick={() =>
                          handleCardClick({
                            id,
                            name,
                            description,
                            price,
                            image_url,
                            Restaurant_Item_Attributes,
                            chinese_name,
                          })
                        }
                        key={id}
                        ref={(node) => {
                          if (node) {
                            cardRef.current.push(node);
                          }
                        }}
                      >
                        <div className="dz-img-box style-7">
                          {image_url && (
                            <div className="dz-media">
                              <img
                                src={image_url}
                                alt={name}
                                style={{
                                  maxHeight: "200px",
                                  width: "100%",
                                  minHeight: "200px",
                                  objectFit: "cover",
                                }}
                              />
                            </div>
                          )}
                          <div className="dz-content">
                            <h5 className="title">
                              <Link to="#">
                                {language === "en"
                                  ? name && name.length > 43
                                    ? name.slice(0, 43) + "..."
                                    : name
                                  : chinese_name}
                              </Link>
                            </h5>
                            <span className="price">${price}</span>
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            ))}
        </div>
        <Modal
          show={modalVisible}
          onHide={() => setModalVisible(false)}
          size="lg"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {language === "en" ? selectedItem?.name : selectedItem?.chinese_name}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex flex-column">
              <div className="mr-5 hover-zoom">
                {selectedItem?.image_url && (
                  <img
                    src={selectedItem?.image_url}
                    alt={
                      language === "en"
                        ? selectedItem?.name
                        : selectedItem?.chinese_name
                    }
                    className="img-fluid"
                    style={{
                      maxHeight: "350px",
                      minHeight: "350px",
                    }}
                  />
                )}
              </div>
              <div className="m-2">
                {selectedItem?.description &&
                  selectedItem?.description !== "null" && (
                    <p style={{ fontWeight: "bold" }}>
                      Description: {selectedItem?.description}
                    </p>
                  )}
                <p style={{ fontWeight: "bold", fontSize: "1.5rem" }}>
                  Price: {selectedItem?.price}
                </p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setModalVisible(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default MenuStyle4;
