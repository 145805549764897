import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import { Link } from "react-router-dom";
import { useState } from "react";

const Home2OurMenu = ({ restaurant, prev, next }) => {
  const [active, setActive] = useState();

  return (
    <div className="container">
      <Swiper
        className="swiper menu-swiper swiper-visible swiper-item-4"
        slidesPerView={4}
        spaceBetween={30}
        speed={1500}
        loop={true}
        modules={[Autoplay, Navigation]}
        autoplay={{ delay: 1500 }}
        navigation={{
          prevEl: `.${prev}`,
          nextEl: `.${next}`,
        }}
        breakpoints={{
          1200: { slidesPerView: 4 },
          991: { slidesPerView: 3 },
          575: { slidesPerView: 2 },
          240: { slidesPerView: 1 },
        }}
      >
        {restaurant?.Categories?.map((category) =>
          category?.Items?.map((item) => (
            <SwiperSlide className="swiper-slide" key={item.id}>
              <div
                className={`dz-img-box style-4 box-hover ${
                  active === item.id ? "active" : ""
                }`}
                onMouseEnter={() => {
                  setActive(item.id);
                }}
              >
                <div className="menu-detail">
                  {item?.image_url && (
                  <div className="dz-media">
                    <img src={item?.image_url} alt={item?.name} />
                  </div>
                  )}
                  <div className="dz-content">
                    <h6 className="title">
                      <Link to="/online-order">{item?.name}</Link>
                    </h6>
                    {/* <p>{item?.description}</p> */}
                  </div>
                </div>
                <div className="menu-footer">
                  <span>Regular Price</span>
                  <span className="price">${item?.price}</span>
                </div>
                <Link className="detail-btn" to="/online-order">
                  <i className="fa-solid fa-plus"></i>
                </Link>
              </div>
            </SwiperSlide>
          ))
        )}
      </Swiper>
    </div>
  );
};

export default Home2OurMenu;
