const apiUrl = process.env.REACT_APP_API_URL;


const CHECK_COUPON = 'coupons/CHECK_COUPON';
const CLEAR_COUPON = 'coupons/CLEAR_COUPON';

const checkCoupon = (coupon) => ({
    type: CHECK_COUPON,
    coupon
});

export const clearCoupon = () => ({
    type: CLEAR_COUPON
});

export const checkCouponThunk = (code) => async (dispatch) => {
    try {
        const response = await fetch(`${apiUrl}/api/coupons/validate/${code}`);

        if (response.ok) {
            const data = await response.json();
            dispatch(checkCoupon(data));
        } 
    } catch (error) {
        console.error('Error validating coupon:', error);
        throw error; // Rethrow the error to be caught by the caller
    }
};

const initialState = {
    coupon: {}
};

const couponReducer = (state = initialState, action) => {
    switch (action.type) {
        case CHECK_COUPON:
            return {
                ...state,
                coupon: action.coupon
            };
        case CLEAR_COUPON:
            return {
                ...state,
                coupon: {}
            };
        default:
            return state;
    }
};

export default couponReducer;
